import { application } from "./application"

import CalendlyEmbedController from "./calendly_embed_controller"
application.register("calendly-embed", CalendlyEmbedController)

import HybridPartController from "./hybrid_part_controller"
application.register("hybrid-part", HybridPartController)

import MaternityLeaveCalculatorController from "./maternity_leave_calculator_controller"
application.register("maternity-leave-calculator", MaternityLeaveCalculatorController)

import PricingCalculatorController from "./pricing_calculator_controller"
application.register("pricing-calculator", PricingCalculatorController)

import ShadowFormController from "./shadow_form_controller"
application.register("shadow-form", ShadowFormController)

import TurboFormSubmitRedirectController from "./turbo_form_submit_redirect_controller"
application.register("turbo-form-submit-redirect", TurboFormSubmitRedirectController)

/*-----------------------------------*/
/*     ViewComponent Controllers     */
/*-----------------------------------*/

import InputController from "../../../components/input/input_controller";
application.register("input", InputController);

import CommentController from "../../../components/comment/comment_controller";
application.register("comment", CommentController);

import AlertController from "../../../components/alert/alert_controller";
application.register("alert", AlertController);
