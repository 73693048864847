import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="comment"
export default class extends Controller {
  static targets = ["textarea"];

  textareaTargetConnected(textarea) {
    textarea.setAttribute("style", "overflow-y:hidden;resize:none;");

    const setHeight = () => {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    };

    textarea.addEventListener("input", setHeight);
    textarea.closest(".js-accordion")?.addEventListener("click", setHeight);
  }
}
