import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pricing-calculator"

// Controller for updating the pricing component. This has 5 different inputs, each of which
// triggers the calculateCosts function. This works by getting the values of each input, establishing
// a base cost by checking the stored COST_MATRIX. Then it proceeds to add the recruit and advice values
// if those have been checked. Finally the value for the total cost is updated in the view
export default class extends Controller {
  static targets = ["dailyCopy"]

  connect() {
    this.totalCosts = {
      standard: 0,
      plus: 0,
    }
  }

  dailyCopyTargetConnected() {
    this.variant = true;
  }

  find = (selector) => (
     document.querySelector('#ento-part-pricing').shadowRoot.querySelector(selector)
  )

  calculateCosts() {
    const teamMemberCount = this.find('#team-member-count').value
    const costsForSelectedCount = COST_MATRIX[teamMemberCount]
    const productTypes = ['standard', 'plus']

    productTypes.forEach((productType) => {
      this._updateCosts(productType, costsForSelectedCount)
    })
  }

  _updateCosts(productType, costsForSelectedCount) {
    // Cost per month:
    let additionalCosts = 0
    const shouldAddAdvice = this.find(`#charlie-${productType}-advice`).checked
    const shouldAddRecruit = this.find(`#charlie-${productType}-recruit`).checked

    if (shouldAddAdvice) additionalCosts += costsForSelectedCount.advice
    if (shouldAddRecruit) additionalCosts += costsForSelectedCount.recruit

    const totalCost = costsForSelectedCount[productType] + additionalCosts
    this.totalCosts[productType] = totalCost
    this.find(`#charlie-${productType}-cost`).textContent = `£${totalCost}`

    // Cost per day:
    if (this.variant) {
      const pounds = Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
      })

      const costPerDay = (totalCost * 12) / 365
      this.find(`#daily-${productType}-cost`).textContent = pounds.format(costPerDay)
      // Only show cost per day for amounts less than £4.00
      this.find(`#daily-${productType}-copy`).classList.toggle('hidden', costPerDay >= 4)
    }
  }

  storeAnswers() {
    this.calculateCosts();

    const cookieData = {
      teamMemberCount: this.find("#team-member-count").selectedOptions[0].textContent,
      charlieRecruit: this.find("#charlie-standard-recruit").checked,
      amount: this.totalCosts.standard
    }

    const serializedCookieData = JSON.stringify(cookieData)
    document.cookie = `pricing_data=${serializedCookieData};`
  }

  storePlusAnswers() {
    this.calculateCosts();

    const cookieData = {
      teamMemberCount: this.find("#team-member-count").selectedOptions[0].textContent,
      charlieRecruit: this.find("#charlie-plus-recruit").checked,
      charliePlus: true,
      amount: this.totalCosts.plus
    }

    const serializedCookieData = JSON.stringify(cookieData)
    document.cookie = `pricing_data=${serializedCookieData};`
  }
}

const COST_MATRIX = {
  one: {
    standard: 5,
    plus: 30,
    advice: 179,
    recruit: 55,
  },
  five: {
    standard: 40,
    plus: 60,
    advice: 179,
    recruit: 55,
  },
  ten: {
    standard: 40,
    plus: 60,
    advice: 299,
    recruit: 55,
  },
  fifteen: {
    standard: 100,
    plus: 120,
    advice: 299,
    recruit: 85,
  },
  thirty: {
    standard: 170,
    plus: 200,
    advice: 299,
    recruit: 85,
  },
  fifty: {
    standard: 300,
    plus: 360,
    advice: 299,
    recruit: 100,
  },
  one_hundred: {
    standard: 400,
    plus: 480,
    advice: 499,
    recruit: 100,
  },
  hundred_and_fifty: {
    standard: 500,
    plus: 600,
    advice: 499,
    recruit: 100,
  },
  two_hundred: {
    standard: 600,
    plus: 720,
    advice: 499,
    recruit: 150,
  },
  two_hundred_and_fifty: {
    standard: 700,
    plus: 840,
    advice: 499,
    recruit: 150,
  },
}
