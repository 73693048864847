import { Application, Controller } from "@hotwired/stimulus"
import CalendlyEmbedController from "./calendly_embed_controller"
import PricingCalculatorController from "./pricing_calculator_controller"
import MaternityLeaveCalculatorController from "./maternity_leave_calculator_controller"
import { DesignSystemDatepicker } from '../../../components/datepicker/index.js'
import { DesignSystemTooltip } from '../../../components/tooltip/index.js'
export default class extends Controller {
  static targets = ['template']
  #movedToShadow;

  initialize() {
    const { templateTarget } = this;
    this.#movedToShadow = false;
    this.moveFragmentToShadow();
  }

  connect() {
    this.moveFragmentToShadow();
  }

  disconnect() {
    this.#movedToShadow = false;
  }

  moveFragmentToShadow() {
    if (this.#movedToShadow) {
      return;
    }
    this.#movedToShadow = true;

    const shadowRoot = this.element.attachShadow({ mode: 'open' });
    const templateContent = this.templateTarget.content;

    // Exclude script nodes from template content
    const filteredContent = Array.from(templateContent.childNodes).filter(node => node.nodeName !== 'SCRIPT');

    // Append filtered content to shadow DOM
    shadowRoot.append(...filteredContent);
    if (this.element.id === "ecto-part-nav") {
      window.navShadowRoot = shadowRoot;
    }
    else {
      window.shadowRoot = shadowRoot;
    }

    // Find and execute script tags separately
    templateContent.querySelectorAll('script').forEach(scriptElement => {
      // Append the modified script to the shadow root
      shadowRoot.appendChild(scriptElement);
    });

    // Register nested stimulus controller
    this.shadowApp = Application.start(shadowRoot.querySelector('div'));
    this.shadowApp.register("calendly-embed", CalendlyEmbedController)
    this.shadowApp.register("pricing-calculator", PricingCalculatorController)
    this.shadowApp.register("maternity-leave-calculator", MaternityLeaveCalculatorController)


    shadowRoot.querySelectorAll(".js-datepicker").forEach((target) => {
      const $datepicker = $(target)

      if (!$datepicker.data('initialized')) {
        $datepicker.data('instance', new DesignSystemDatepicker($datepicker))
        $datepicker.data('instance').init()
        $datepicker.data('initialized', true)
      }
    })

    shadowRoot.querySelectorAll(".js-tooltip").forEach((target) => {
      const $tooltip = $(target)
      const controlTarget = $tooltip.data("control")
      const control = shadowRoot.querySelector(controlTarget)

      if (!$tooltip.data('initialized')) {
        new DesignSystemTooltip($tooltip, control).init()
        $tooltip.data('initialized', true)
      }
    })
  }
}
